import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import MarkDown from "react-markdown"
import ContactForm from "../components/Contactus/ContactForm"
import { Fade } from "react-reveal"

//  component
import Layout from "../components/layout"
import Seo from "../components/seo"
import LiveChat from "../components/Home/livechat"

// images
import decodeCluth from "../../images/uploads/decode-cluth-rev.svg"
import decodeUpwork from "../../images/uploads/decodeup-upwork.svg"
import decodeCluthDark from "../../images/uploads/decode-cluth-rev-dark.svg"
import decodeUpworkDark from "../../images/uploads/decodeup-upwork-dark.svg"

const GetIntouch = props => {

  const [shouldAnimate, setShouldAnimate] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      setShouldAnimate(true)
    }, 100)
  }, [])



  const info = props.data.getIntouchInfo
  const SEOdata =
    props.data.awardpageData.edges[0].node.frontmatter?.seo_metadata
  const Schemadata = props.data.awardpageData.edges[0].node.frontmatter?.Schema
  const Hreflang = props.data.awardpageData.edges[0].node.frontmatter?.Hreflang

  return (
    <>

      <Layout>
        <Seo
          title={SEOdata ? SEOdata.seo_title : "Get In Touch"}
          description={SEOdata ? SEOdata.seo_description : ""}
          keywords={SEOdata ? SEOdata.seo_keyword : ""}
          image={
            SEOdata
              ? SEOdata.seo_Image?.childImageSharp?.gatsbyImageData?.images
                ?.fallback?.src
              : ""
          }
          single_image={SEOdata ? SEOdata.seo_Single_Image : ""}
          currentPageUrlPath={props.location.pathname}
          image_format={SEOdata ? SEOdata.image_format : ""}
          image_height={SEOdata ? SEOdata.feature_image_height : ""}
          img_width={SEOdata ? SEOdata.img_width : ""}
          facebook_url={SEOdata ? SEOdata.facebook_url : ""}
          twitter_page_username={SEOdata ? SEOdata.twitter_page_username : ""}
          twitter_image_alt_tag={SEOdata ? SEOdata.twitter_image_alt_tag : ""}
          twitter_estimated_reading_lable={
            SEOdata ? SEOdata.twitter_estimated_reading_lable : ""
          }
          twitter_estimated_reading_time={
            SEOdata ? SEOdata.twitter_estimated_reading_time : ""
          }
          page_publish_date={SEOdata ? SEOdata.page_publish_date : ""}
          page_last_modified_time={
            SEOdata ? SEOdata.page_last_modified_time : ""
          }
          sitelinks_schema={Schemadata ? Schemadata.sitelinks_schema : ""}
          breadcrumb_schema={Schemadata ? Schemadata.breadcrumb_schema : ""}
          logo_schema={Schemadata ? Schemadata.logo_schema : ""}
          organization_schema={Schemadata ? Schemadata.organization_schema : ""}
          aggregate_rating_schema={
            Schemadata ? Schemadata.aggregate_rating_schema : ""
          }
          local_business_schema={
            Schemadata ? Schemadata.local_business_schema : ""
          }
          site_navigation_schema={
            Schemadata ? Schemadata.site_navigation_schema : ""
          }
          speakable_specification_schema={
            Schemadata ? Schemadata.speakable_specification_schema : ""
          }
          hreflang1={Hreflang ? Hreflang.hreflang_1 : ""}
          hreflang2={Hreflang ? Hreflang.hreflang_2 : ""}
          hreflang3={Hreflang ? Hreflang.hreflang_3 : ""}
          hreflang4={Hreflang ? Hreflang.hreflang_4 : ""}
        />
        {!shouldAnimate && !shouldAnimate ? (
          <div className="min-h-screen"> </div>
        ) : (
          <section className="pt-1-20 lg:pt-1-76 pb-20 relative">
            {/* New Contact form section */}
            <div className="container mx-auto">
              <div className="h-full flex justify-center items-center flex-col md:flex-row gap-12 lg:gap-16 dec-getouch">
                <div className="pl-0 lg:pl-20 xl:pl-1-04 w-full md:w-1/2 lg:w-3/5 xl:w-2/3 lg:flex-1">
                  <Fade bottom delay={100} duration={1000}>
                    <h4 className="mb-2 md:mb-4 text-rh6 lg:text-h4 text-cyan font-poppings-bold uppercase">
                      LET’S CONNECT
                    </h4>
                  </Fade>
                  <Fade bottom delay={200} duration={1000}>
                    <h1 className="mb-3 md:mb-6 xl:mb-8 font-poppings-bold text-rh2 xl:text-h1 text-term-primary text-left">
                      Let’s discuss to grow your business{" "}
                    </h1>
                  </Fade>
                  <Fade bottom delay={400} duration={1000}>
                    <ul className="mb-8 lg:mb-12 flex flex-col gap-3 list-none text-term-primary font-worksans-normal text-p4 lg:text-p3 xl:text-p2 dec-cstm-li">
                      <li className="pl-6 lg:pl-8 xl:pl-10 relative">
                        Tell as about your requirement.
                      </li>
                      <li className="pl-6 lg:pl-8 xl:pl-10 relative">
                        Brief us about your business.
                      </li>
                      <li className="pl-6 lg:pl-8 xl:pl-10 relative">
                        Find out if we’re a good fit.
                      </li>
                      <li className="pl-6 lg:pl-8 xl:pl-10 relative">
                        Get the details about our services.
                      </li>
                    </ul>
                  </Fade>
                  <Fade bottom delay={600} duration={1000}>
                    <div className="mb-8 lg:mb-12">
                      <h4 className="mb-4 text-h4 text-term-primary font-poppings-bold ">
                        Our Trusted Reviews
                      </h4>
                      <div className="flex items-center">
                        <div className="pr-4 lg:pr-6 ">
                          <img
                            className="w-full h-full deco-rev-img  img-light "
                            src={decodeCluth}
                            alt="DecodeUp logo"
                          />
                          <img
                            className="w-full h-full deco-rev-img img-dark "
                            src={decodeCluthDark}
                            alt="DecodeUp logo"
                          />
                        </div>
                        <div className="pl-4 lg:pl-6 border-l ">
                          <img
                            className="w-full h-full deco-rev-img img-light"
                            src={decodeUpwork}
                            alt="DecodeUp logo"
                          />
                          <img
                            className="w-full h-full deco-rev-img img-dark"
                            src={decodeUpworkDark}
                            alt="DecodeUp logo"
                          />
                        </div>
                      </div>
                    </div>
                  </Fade>
                  <Fade bottom delay={800} duration={1000}>
                    <div>
                      <h4 className="mb-0 text-h4 text-term-primary font-poppings-bold ">
                        Connect With Us
                      </h4>
                      <div className="text-rp3 lg:text-p3 text-term-primary font-worksans-normal">
                        <a
                          href={`mailto:sales@decodeup.com`}
                          className=""
                        >
                          sales@decodeup.com
                        </a>
                      </div>
                    </div>
                  </Fade>
                </div>

                <div className="w-full md:w-1/2 lg:w-2/5 xl:w-1/3 de-get-intouch">
                  <Fade bottom distance="60px" delay={100}>
                    <ContactForm />
                  </Fade>
                </div>
              </div>
            </div>
          </section>
        )}

        {/* office information section */}

        <section className="pb-50 lg:pt-54 lg:pb-95">
          <div className="container mx-auto">
            <div className="lg:w-4/5 xl:w-5/8 mx-auto">
              <div className="grid lg:col-gap-4 xl:col-gap-69 sm:grid-cols-3">
                <div className="mb-4-1 lg:mb-0 flex sm:block text-left sm:text-center">
                  <div className="sm:mb-2 lg:mb-4-2 h-60 text-center">
                    <img
                      src={
                        info.frontmatter.company_info.office_address.svg
                          .publicURL
                      }
                      className="object-cover w-auto h-auto mx-auto"
                      alt="getLocation decodeup-address"
                    ></img>
                  </div>
                  <div className="ml-5-3 w-3/4 lg:ml-0 lg:w-full">
                    <h5 className="sm:mb-2 lg:mb-4 uppercase text-cyan font-poppings-bold text-rh6 md:text-h6">
                      OFFICE ADDRESS
                    </h5>
                    <MarkDown
                      className="text-term-primary font-poppings-bold text-p4 lg:text-p2"
                      children={
                        info.frontmatter.company_info.office_address.address
                      }
                      allowDangerousHtml
                    />
                  </div>
                </div>
                <div className="mb-4-1 lg:mb-0 flex sm:block text-left sm:text-center">
                  <div className="sm:mb-2 lg:mb-4-2 h-60 text-center">
                    <img
                      src={
                        info.frontmatter.company_info.get_a_call.svg.publicURL
                      }
                      className="object-cover w-auto h-auto mx-auto"
                      alt="getCall decodeup-call"
                    ></img>
                  </div>
                  <div className="ml-5-3 w-3/4 lg:ml-0 lg:w-full">
                    <h5 className="sm:mb-2 lg:mb-4 uppercase text-cyan font-poppings-bold text-rh6 md:text-h6">
                      {" "}
                      GIVE US A CALL NOW{" "}
                    </h5>
                    <p className="text-term-primary font-poppings-bold text-p4 lg:text-p2">
                      <a
                        href={`tel:+${info.frontmatter.company_info.get_a_call.mobile}`}
                      >
                        {" "}
                        +{info.frontmatter.company_info.get_a_call.mobile}
                      </a>
                      <br></br>
                      <a
                        href={`tel:+${info.frontmatter.company_info.get_a_call.telephone}`}
                      >
                        {" "}
                        +{info.frontmatter.company_info.get_a_call.telephone}
                      </a>
                    </p>
                  </div>
                </div>
                <div className="mb-4-1 lg:mb-0 flex sm:block text-left sm:text-center">
                  <div className="sm:mb-2 lg:mb-4-2 h-60 text-center">
                    <img
                      src={
                        info.frontmatter.company_info.chat_call_skype.svg
                          .publicURL
                      }
                      className="object-cover w-auto h-auto mx-auto"
                      alt="getSkype decodeup-messages"
                    ></img>
                  </div>
                  <div className="ml-5-3 w-3/4 lg:ml-0 lg:w-full">
                    <h5 className="sm:mb-2 lg:mb-4 uppercase text-cyan font-poppings-bold text-rh6 md:text-h6">
                      {" "}
                      Chat or call us on skype{" "}
                    </h5>
                    <p className="text-term-primary font-poppings-bold text-p4 lg:text-p2">
                      <a
                        href={
                          info.frontmatter.company_info.chat_call_skype.link
                        }
                        className="underline"
                      >
                        Direct link to skype
                      </a>
                      <br></br>
                      <span>
                        ID:{" "}
                        {info.frontmatter.company_info.chat_call_skype.skype_id}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-4 lg:mt-10 xl:mt-77 sm:text-center">
                <a
                  href={info.frontmatter.locate_office_link}
                  target="_blank"
                  rel="noreferrer"
                  className=" text-right py-2-2 md:py-3 pl-6-4 pr-6-5 font-poppings-bold inline-block bg-celeste text-rh5 lg:text-h5 text-dark-blue text-center shadow-1 hover:shadow-none transition-all duration-100 ease-in-out delay-0"
                >
                  Locate Our Office
                </a>
              </div>
            </div>
          </div>
        </section>
        <LiveChat />
      </Layout>

    </>
  )
}

export default GetIntouch

export const pageQuery = graphql`
  query {
    getIntouchInfo: markdownRemark(
      fileAbsolutePath: { regex: "/getInTouch.md/" }
    ) {
      frontmatter {
        title
        sub_title
        description
        company_info {
          chat_call_skype {
            link
            skype_id
            svg {
              publicURL
            }
          }
          get_a_call {
            mobile
            telephone
            svg {
              publicURL
            }
          }
          office_address {
            address
            svg {
              publicURL
            }
          }
        }
        locate_office_link
      }
    }
    awardpageData: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/getInTouch.md/" } }
    ) {
      edges {
        node {
          frontmatter {
            seo_metadata {
              seo_title
              seo_keyword
              seo_description
              image_format
              feature_image_height
              img_width
              facebook_url
              twitter_page_username
              twitter_image_alt_tag
              twitter_estimated_reading_lable
              twitter_estimated_reading_time
              page_publish_date
              page_last_modified_time
              seo_Image {
                childImageSharp {
                  gatsbyImageData(layout: FIXED, width: 1200)
                }
              }
              seo_Single_Image
            }
            Schema {
              sitelinks_schema
              breadcrumb_schema
              logo_schema
              organization_schema
              aggregate_rating_schema
              local_business_schema
              site_navigation_schema
              speakable_specification_schema
            }
            Hreflang {
              hreflang_1
              hreflang_2
              hreflang_3
              hreflang_4
            }
          }
        }
      }
    }
  }
`
